import { IVisionProps } from "../models/vision-props";

const Hydro = (props: IVisionProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 41.34 41.286"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_33"
          data-name="Rectangle 33"
          width="41.34"
          height="41.286"
          fill={props.color}
        />
      </clipPath>
    </defs>
    <g id="Groupe_31" data-name="Groupe 31" transform="translate(0)">
      <g id="Groupe_9" data-name="Groupe 9" transform="translate(0)">
        <g id="Groupe_8" data-name="Groupe 8">
          <path
            id="Tracé_8"
            data-name="Tracé 8"
            d="M12.535,6.053A23.706,23.706,0,0,1,16.9,2.819,20.465,20.465,0,0,1,31.893.549a20.229,20.229,0,0,1,14.137,11.5,19.341,19.341,0,0,1,1.923,7.362,1.153,1.153,0,0,0,.062.231q0,1.11,0,2.219-.227,1.6-.454,3.2l-.037.242-.986,3.192-.062.169a1.473,1.473,0,0,0-.4.818l-.072.2a.356.356,0,0,0-.214.407.053.053,0,0,0-.034.079,1.265,1.265,0,0,0-.448.782.059.059,0,0,0-.041.083c-.256.132-.328.407-.468.623a21.007,21.007,0,0,1-9.922,8.211,20.672,20.672,0,0,1-6.294,1.421H25.994a29.033,29.033,0,0,1-3.385-.508,11.832,11.832,0,0,1-6.58-3.647c-.114-.124-.343-.251-.183-.443.131-.159.3.021.434.089a30.334,30.334,0,0,0,4.012,1.667,13.032,13.032,0,0,0,3.63.765l.368.039c.5.068,1.005.153,1.511.2a11.694,11.694,0,0,0,5.724-.74,11.156,11.156,0,0,0-7.106-21.1,3.71,3.71,0,0,1-2.167.212,2.838,2.838,0,0,1-2-2.528,2.676,2.676,0,0,1,1.847-2.749,13.661,13.661,0,0,1,9.834-.238c5.337,1.937,8.685,5.7,9.582,11.276.929,5.763-1.216,10.462-5.863,14.006-.166.127-.4.2-.469.447.344-.028.345-.026.518-.125a16.253,16.253,0,0,0,7.585-9.339,11.26,11.26,0,0,0,.727-3.892q.006-.188.012-.375a2.351,2.351,0,0,0,.068-.354,15.731,15.731,0,0,0-1.876-8.331A16.629,16.629,0,0,0,30.565,6.94a10.6,10.6,0,0,0-3.589-.279.751.751,0,0,0-.48,0,4.967,4.967,0,0,0-.6-.029,15.4,15.4,0,0,0-4.541,1.126A12.884,12.884,0,0,0,17.841,9.67l-.051.011-.015.05a.256.256,0,0,0-.29.194l-.1.088a.387.387,0,0,0-.379.28.07.07,0,0,0-.086.056.471.471,0,0,0-.4.321,6.7,6.7,0,0,0-1.463,1.457.467.467,0,0,0-.332.405l-.014.014a.563.563,0,0,0-.354.469.055.055,0,0,0-.05.075.821.821,0,0,0-.437.54l-.4.392a3.281,3.281,0,0,1-2.217.459,2.575,2.575,0,0,1-2.279-3.349A6.562,6.562,0,0,1,9.807,9.5c.817-1.222,1.8-2.31,2.725-3.447ZM35.1,38.142c-.039-.022-.081-.065-.124-.065-.1,0-.206.025-.225.146,0,.023.071.083.111.084a.207.207,0,0,0,.238-.165"
            transform="translate(-6.674 0)"
            fill={props.color}
          />
          <path
            id="Tracé_9"
            data-name="Tracé 9"
            d="M59.144,94.7l.475-.069a1.023,1.023,0,0,0,.752-.059l.842,0a.5.5,0,0,0,.507.045,1.74,1.74,0,0,0,.722.072.62.62,0,0,0,.629.183l.232.058a.169.169,0,0,0,.248.12A7.81,7.81,0,0,1,66.6,97.406c1.215,1.7,1.692,3.978.208,5.746-.356.425-.932.435-1.408.631a2.566,2.566,0,0,0-.868-.029l-.231-.057a.173.173,0,0,0-.24-.125l-.156-.06a.6.6,0,0,0-.455-.3.651.651,0,0,0-.342-.345.568.568,0,0,0-.308-.453l-.064-.154a.177.177,0,0,0-.141-.238l-.081-.27a.265.265,0,0,0-.156-.346l-.061-.23a.587.587,0,0,0-.242-.6l.012-.039-.03-.027a.761.761,0,0,0-.483-.635l-.025-.02a.261.261,0,0,0-.287-.216l-.158-.06a.526.526,0,0,0-.61-.221,1.4,1.4,0,0,0-.984-.056,1.817,1.817,0,0,0-1.572.557l-.044.023c-.17-.009-.252.1-.32.233a.364.364,0,0,0-.264.215c-.18,0-.26.124-.313.273l-.013.013c-.151.052-.27.133-.272.314a1.448,1.448,0,0,0-.532.973.165.165,0,0,0-.1.251,3.345,3.345,0,0,0-.089,1.837.631.631,0,0,0,.158.632,2.779,2.779,0,0,0,.858,1.61.387.387,0,0,0,.292.362,4.057,4.057,0,0,0,2.205,1.394.162.162,0,0,0,.247.1,1,1,0,0,0,.618.118,2.859,2.859,0,0,0,2.114.014.753.753,0,0,0,.472-.085,1.958,1.958,0,0,0,1.218-.466l.16-.061a.327.327,0,0,0,.331-.19,2.56,2.56,0,0,0,1.026-.669,2.121,2.121,0,0,0,.938-.83c.1,0,.164-.032.172-.141.171.118-.039.271.037.4a8.857,8.857,0,0,1-3.765,2.812.169.169,0,0,0-.247.112l-.505.1a.417.417,0,0,0-.48.118l-2.057.006a.424.424,0,0,0-.491-.1l-.616-.126a.272.272,0,0,0-.348-.145l-.385-.135c-.032-.123-.114-.147-.226-.114l-.17-.056a.282.282,0,0,0-.32-.191,10.922,10.922,0,0,1-2.3-1.927l-.888-1.389-.106-.316a3.48,3.48,0,0,0-.364-.974l-.109-.642.014-.03-.02-.026c.016-.12.041-.243-.109-.3l0,0a.315.315,0,0,0-.007-.362.123.123,0,0,0-.023-.2.608.608,0,0,0-.023-.517.682.682,0,0,0,.053-.586l.028-.13a.153.153,0,0,0,.043-.25l.053-.3,0-.025a11.329,11.329,0,0,1,.44-1.72c.072-.021.186-.02.209-.065a15.556,15.556,0,0,1,1.3-1.873l.015-.015a.246.246,0,0,0,.231-.225l.022-.019a.44.44,0,0,0,.354-.325l.03-.03a4.625,4.625,0,0,0,1.232-.83c.521-.133.937-.54,1.5-.576a.258.258,0,0,0,.373-.112"
            transform="translate(-40.177 -71.329)"
            fill={props.color}
          />
          <path
            id="Tracé_10"
            data-name="Tracé 10"
            d="M2.736,73.8a3.047,3.047,0,0,1,2.47,1.363A2.722,2.722,0,0,1,4,79.213c-.143.064-.331.049-.41.228l-.355.068a1.644,1.644,0,0,0-.746.007l-.358-.051L.7,78.58.7,78.573A4.037,4.037,0,0,1,0,76.458l.075-.522a3.113,3.113,0,0,0,.5-.971l.592-.588c.163-.014.31-.052.361-.237L1.9,74a.28.28,0,0,0,.364-.132l.477-.071"
            transform="translate(0 -55.667)"
            fill={props.color}
          />
          <path
            id="Tracé_11"
            data-name="Tracé 11"
            d="M19.075,117.664a2.139,2.139,0,0,1-.154-2.094,1.972,1.972,0,1,1,3.492,1.83c-.065.124-.192.218-.178.379l-.711.52a.762.762,0,0,0-.624.2h-.355l-.971-.382-.5-.456"
            transform="translate(-14.111 -86.355)"
            fill={props.color}
          />
        </g>
      </g>
    </g>
  </svg>
);

export { Hydro };
